<template>
    <div class="brand">
      <router-link to="/">
        <img class="brand__logo" src="../assets/images/logo.png" alt="">
      </router-link>
  </div>
</template>

<script>



  export default {
    name: "HeaderComponent",
  };
</script>
